import React from 'react';
import { useState, useEffect, useRef } from 'react';
/*import { useLocation } from 'react-router-dom';  // Importa useLocation - funcion detectar pagina beta*/
import './chatbot.css';
import '../App.css';
import axios from 'axios'

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import chatAvatar from '../chatbot-avatar.png'


/* Las variables filteredCategoriesPreguntas1 y preguntasPorCategoria llegan desde la app principal con los datos filtrados para mostrarse en el chatbot, La variable chatbotInput se ocupa para filtrar estos datos*/
/* aunque ahora se usa input de usuario en chat solamente */
const Chatbot = ({ filteredCategoriesPreguntas1, preguntasPorCategoria, chatbotInput }) => {

  // Verifica la ruta actual del navegador
  const [isPathAllowed, setIsPathAllowed] = useState(false);

  useEffect(() => {
    const currentPath = window.location.pathname;
    //que chatbot solo funcione en produccion raiz /, evitando posibles exploits o hackeos
    const allowedPath = process.env.REACT_APP_ALLOWED_PATH || '/';

    // Establece si la ruta actual es la ruta permitida
    setIsPathAllowed(currentPath.startsWith(allowedPath));
  }, []); // Se ejecuta una vez cuando el componente se monta

  ////fin /beta

  const [email, setEmail] = useState('');
  const [isEmailValid, setIsEmailValid] = useState(false);
  const [emailError, setEmailError] = useState('');
  const [emailSubmitted, setEmailSubmitted] = useState(false);
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(false);
  const [isRated, setIsRated] = useState(false);
  const [opinionMessage, setOpinionMessage] = useState("¿Fue útil la respuesta que te di?");
  /* Constante para controlar la visualizacion del chat*/
  const [showChat, setShowChat] = useState(false);
  /* Constante para el input de chatbot */
  const [chatBotInputValue, setChatBotInputValue] = useState('');
  const maxCharacters = 300;

  const [isTyping, setIsTyping] = useState(false);
  const [chatResponse, setChatResponse] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  /* constante para array del historial del chat */

  const [chatHistorial, setChatHistorial] = useState(() => {
    const savedHistorial = localStorage.getItem("chatHistorial");
    return savedHistorial
      ? JSON.parse(savedHistorial)
      : [{ rol: 'bot', mensaje: 'Hola soy tu asistente virtual. ¿Cómo puedo ayudarte?' }];
  });
  /* Funcion para alternar la visualizacion del chatbot */
  const toggleChat = () => {
    setShowChat(!showChat);

    if (!showChat) {

      window.dispatchEvent(new CustomEvent('chatbotOpened'));

      setTimeout(() => {
        if (endOfMessagesRef.current) {
          endOfMessagesRef.current.scrollIntoView({ behavior: 'smooth' });
        }
      }, 0);
    } else {
      window.dispatchEvent(new CustomEvent('chatbotClosed'));

      if (isRated) {
        const firstMessage = chatHistorial.slice(0, 1);

        setChatHistorial(firstMessage);
        setIsRated(false);
        setIsEmailValid(false);
        localStorage.setItem("chatHistorial", JSON.stringify(firstMessage));
      }
    }

  };

  const validarEmail = (event) => {
    const value = event.target.value;
    setEmail(value);
  };

  const handleEmailInput = (event) => {
    const value = event.target.value;
    setEmail(value);
    validarEmail(value);
  };

  const handleEmailSubmit = (e) => {
    e.preventDefault();
    const emailRegex = /^[a-zA-Z0-9._%+-]+@(duocuc\.cl|duoc\.cl)$/i;
    if (emailRegex.test(email)) {
      setIsEmailValid(true); 
      setEmailSubmitted(true);
      setShowChat(true);
      setEmailError('');
    } else {
      setIsEmailValid(false);
      setEmailError('Por favor, introduce un correo válido');
    }
  };

  const handleEmailKeyPress = (e) => {
    if (e.key === 'Enter') {
      handleEmailSubmit(e);
    }
  };

  const endOfMessagesRef = useRef(null);
  useEffect(() => {
    if (endOfMessagesRef.current) {
      endOfMessagesRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [chatHistorial]);


  const handleInputChange = (e) => {
    const value = e.target.value;
    if (value.length <= maxCharacters) {
      setChatBotInputValue(value);
      chatbotInput(value);
    }
    setIsTyping(true);
  };

  const handleKeyPress = async (e) => {
    if (e.key === 'Enter' && chatBotInputValue.trim() !== '') {
      // Añadir el mensaje del input al historial de manera inmediata
      setChatHistorial(prev => [
        ...prev,
        { rol: 'user', mensaje: chatBotInputValue }
      ]);
      
      setIsTyping(false);
      setIsLoading(true);
      setChatBotInputValue(""); // Borrar el input field apenas haya un nuevo mensaje
  
      // Añadir el mensaje de carga
      setChatHistorial(prev => [
        ...prev,
        { rol: 'bot', mensaje: (
          <div style={{ margin: "20px", display: 'flex', justifyContent: 'flex-start' }}>
            <div className='typingIndicator'>
              <span className="ellipsis"></span>
              <span className="ellipsis"></span>
              <span className="ellipsis"></span>
            </div>
          </div>
        ) }
      ]);
  
      try {
        const response = await axios.post('/consulta', {
          input: chatBotInputValue,
          historial: chatHistorial,
        });
  
        console.log('Response: ', response);
        console.log('Error 1: ', response.data.error_t);
  
        // Borrar el mensaje de carga una vez la API responda
        setChatHistorial(prev => [
          ...prev.slice(0, -1),
          { rol: 'bot', mensaje: response.data.response }
        ]);
  
        setChatResponse(response.data.response);
      } catch (error) {
        console.log('Error:', error);
  
        // Remplazar con la respuesta de la API
        setChatHistorial(prev => [
          ...prev.slice(0, -1),
          { rol: 'bot', mensaje: 'Error: No se pudo acceder al servicio.' }
        ]);
      } finally {
        setIsLoading(false);
      }
    }
  };

  //para limpiar cache del chat (borrar los datos)
  //localStorage.removeItem('chatHistorial');

  const handleOpinion = (opinion) => {
    if (opinion === true) {
      setOpinionMessage('Gracias por utilizar este chatbot.');
    } else if (opinion === false) {
      setOpinionMessage("Sentimos no haber podido ayudar. Contacte a 'Servicios Digitales' directamente presionando el botón 'contacto' en la barra de navegación para resolver sus dudas.");
    }
  };

  const handleThumbsUp = async () => {
    try {
      await axios.post('/saveOpinion', {
        opinion: true,          
        chatlog: chatHistorial,
        mail: email             
      });
      console.log('Opinion positiva');
    } catch (error) {
      console.error('Error:', error);
    }

    setIsRated(true);
    handleOpinion(true);
  };
  
  const handleThumbsDown = async () => {
    try {
      await axios.post('/saveOpinion', {
        opinion: false,         
        chatlog: chatHistorial,
        mail: email 
      });
      console.log('Opinion negativa');
    } catch (error) {
      console.error('Error:', error);
    }

    setIsRated(true);
    handleOpinion(false);
  };

  return (

    /*validar que se use direccion /beta en chat*/

    <div>
      {isPathAllowed && (
      <button id="chatButton" onClick={toggleChat}>
        <svg xmlns="http://www.w3.org/2000/svg"
          width="30" height="30" fill="white"
          className="bi bi-chat-left-text"
          viewBox="0 0 16 16">
          <path d="M14 1a1 1 0 0 1 1 1v8a1 1 0 0 1-1 1H4.414A2 2 0 0 0 3 11.586l-2 2V2a1 1 0 0 1 1-1zM2 0a2 2 0 0 0-2 2v12.793a.5.5 0 0 0 .854.353l2.853-2.853A1 1 0 0 1 4.414 12H14a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2z" />
          <path d="M3 3.5a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5M3 6a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9A.5.5 0 0 1 3 6m0 2.5a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5" />
        </svg>
      </button>
      )}

      <div id="chatWindow" style={{ display: showChat ? 'block' : 'none' }}>
        <div className='maxHeight'>
          <div id='chatbotHeader'>
            <h1 className='lato'> SOPORTE </h1>
            <button id="btnCerrar" onClick={toggleChat}>
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" className="bi bi-x-lg" viewBox="0 0 16 16">
                <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8z" />
              </svg>
            </button>
          </div>

          <div className='contentChatbot fontLato'>
            <div className='contenedorChatbotSuperior'>
              <div id="MiniBannerChat">
                <div>
                  Considera que nuestro chat está en aprendizaje continuo.
                </div>
              </div>

              {!isEmailValid && (
                <div className="chatBotEmail">
                  <p>Introduce tu correo institucional para continuar:</p>

                  <div style={{paddingBottom: 10}}>
                    <input 
                      type="text" 
                      id="input-field" 
                      autoComplete="off" 
                      value={email} 
                      onChange={handleEmailInput} 
                      onKeyDown={handleEmailKeyPress}
                      placeholder="Introduce tu correo @duocuc.cl"
                    />
                    <button onClick={handleEmailSubmit}>
                      Continuar
                    </button>
                  </div>

                  {emailError &&
                    <p style={{ color: 'red' }}>
                      {emailError}
                    </p>}
                </div>
              )}

              {isEmailValid && (
                <>
                <div className="chatbot-content">
                  {Array.isArray(chatHistorial) && chatHistorial.map((entry, index) => (
                    <Row className='dialogo' key={index}>
                      {entry.rol === 'bot' && (
                        <Col className='col-auto imagen'>
                          <img
                            src={chatAvatar}
                            className="d-inline-block align-top chat-avatar"
                            alt="" />
                        </Col>
                      )}
                      <Col>
                        <div style={{ display: 'flex', justifyContent: entry.rol === 'bot' ? 'flex-start' : 'flex-end' }}>
                          {entry.rol === 'bot' ? (
                            <div className="cuadro-dialogo bot">
                              <div className="triangulo bot"></div>
                              <p>{entry.mensaje}</p>
                            </div>
                          ) : (
                            <div className="cuadro-dialogo user">
                              <div className="triangulo user"></div>
                              <p>{entry.mensaje}</p>
                            </div>
                          )}
                        </div>
                        {index === chatHistorial.length - 1 && entry.rol === 'bot' && index !== 0 &&
                        typeof entry.mensaje === 'string' && !entry.mensaje.includes('Error') && (
                          <>
                            <div className='thumbs'>
                              {isRated ? (
                                <p>{opinionMessage}</p>
                              ):(
                                <p>¿Fue útil la respuesta que te di?</p>
                              )}
                            </div>
                            <div className='thumbs'>
                              <button className='circulo' onClick={handleThumbsUp} disabled={isRated}
                              style={{ cursor: isRated ? 'not-allowed' : 'pointer' }}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="30" height="22.5"
                                fill={isRated ? '#A9A9A9' : '#829FD9' }>
                                  <path d="M12.3657 0.888071C12.6127 0.352732 13.1484 0 13.75 0C14.9922 0 15.9723 0.358596 16.4904 1.29245C16.7159 1.69889 16.8037 2.13526 16.8438 2.51718C16.8826 2.88736 16.8826 3.28115 16.8826 3.62846L16.8825 7H20.0164C21.854 7 23.2408 8.64775 22.9651 10.4549L21.5921 19.4549C21.3697 20.9128 20.1225 22 18.6434 22H8L8 9H8.37734L12.3657 0.888071Z" />
                                  <path d="M6 9H3.98322C2.32771 9 1 10.3511 1 12V19C1 20.6489 2.32771 22 3.98322 22H6L6 9Z" />
                                </svg>
                              </button>
                              <button className='circulo' onClick={handleThumbsDown} disabled={isRated}
                              style={{ cursor: isRated ? 'not-allowed' : 'pointer' }}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="30" height="22.5"
                                fill={isRated ? '#A9A9A9' : '#FF3333' }
                                viewBox="0 0 24 24">
                                  <path d="M12.3657 23.1119C12.6127 23.6473 13.1484 24 13.75 24C14.9922 24 15.9723 23.6414 16.4904 22.7076C16.7159 22.3011 16.8037 21.8647 16.8438 21.4828C16.8826 21.1126 16.8826 20.7188 16.8826 20.3715L16.8825 17H20.0164C21.854 17 23.2408 15.3523 22.9651 13.5451L21.5921 4.54507C21.3697 3.08717 20.1225 2 18.6434 2H8L8 15H8.37734L12.3657 23.1119Z" />
                                  <path d="M6 15H3.98322C2.32771 15 1 13.6489 1 12V5C1 3.35111 2.32771 2 3.98322 2H6L6 15Z" />
                                </svg>
                              </button>
                            </div>
                          </>
                        )}
                      </Col>
                    </Row>
                  ))}

                  {chatBotInputValue !== '' && (
                    <div style={{ margin: "20px", display: 'flex', justifyContent: 'flex-end', marginRight: "50px" }}>
                      {isTyping && (
                        <div className='typingIndicator'>
                          <span className="ellipsis"></span>
                          <span className="ellipsis"></span>
                          <span className="ellipsis"></span>
                        </div>
                      )}
                    </div>
                  )}

                  <div ref={endOfMessagesRef}></div>
                </div>
                </>
              )}
            </div>
            {isEmailValid && (
              <div className='chatBotInputContainer'>
                <input
                  value={chatBotInputValue}
                  onChange={handleInputChange}
                  onKeyPress={handleKeyPress}
                  disabled={isRated}
                  placeholder={isRated ? '' : 'Escribe tus dudas'}/>
                <p style={{ fontSize: '12px', color: '#555' }}>
                  {maxCharacters - chatBotInputValue.length} caracteres restantes
                </p>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Chatbot;
